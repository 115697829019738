import { formatValueToStandardUnit } from "@/utils/formatPrice";

// Utils
const topLevelItemToGtagItem = (item) => {
  const subtotal = formatValueToStandardUnit(item?.subtotal);
  return {
    item_id: item?.item?.id || item?.itemId,
    item_name: item?.name,
    price: formatValueToStandardUnit(
      (item?.subtotal || item?.price) / item?.quantity,
    ),
    quantity: item?.quantity,
    ...(subtotal && { subtotal: subtotal }),
  };
};

const topLevelItemsToGtagArray = (topLevelItems) => {
  return topLevelItems?.map(topLevelItemToGtagItem);
};

const ItemTypeOptimisedToGtagItem = (item) => {
  return {
    item_id: item?.id,
    item_name: item?.label,
    price: formatValueToStandardUnit(item?.unitPriceFractional),
  };
};

// Events
const signUp = () => {
  const gTagPayload = { method: "Atlas Online" };
  window.gtag("event", "sign_up", gTagPayload);
};
const login = () => {
  const gTagPayload = { method: "Atlas Online" };
  window.gtag("event", "login", gTagPayload);
};

// Takes in ItemTypeOptimised
const addToCart = ({ value, item }) => {
  const gTagPayload = {
    value: formatValueToStandardUnit(value),
    currency: item?.currency || "SGD",
    items: [ItemTypeOptimisedToGtagItem(item)],
  };
  window.gtag("event", "add_to_cart", gTagPayload);
};

// Takes in cartOptimised.topLevelItems as payload
const viewCart = (payload) => {
  const gTagPayload = {
    items: topLevelItemsToGtagArray(payload),
  };
  window.gtag("event", "view_cart", gTagPayload);
};
const removeFromCart = ({ value, item }) => {
  const gTagPayload = {
    value: Math.abs(formatValueToStandardUnit(value)),
    currency: item?.currency || "SGD",
    items: [ItemTypeOptimisedToGtagItem(item)],
  };
  window.gtag("event", "remove_from_cart", gTagPayload);
};
const beginCheckout = (payload) => {
  const gTagPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    items: topLevelItemsToGtagArray(payload.topLevelItems),
  };
  window.gtag("event", "begin_checkout", gTagPayload);
};
const addPaymentInfo = (payload) => {
  const gTagPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload.paymentBreakdown.subtotal),
    items: topLevelItemsToGtagArray(payload.topLevelItems),
  };
  window.gtag("event", "add_payment_info", gTagPayload);
};
const purchase = (payload) => {
  const gTagPayload = {
    currency: "SGD", // Default currency
    value: formatValueToStandardUnit(payload?.paymentBreakdown?.subtotal),
    items: topLevelItemsToGtagArray(payload?.orderItems),
    transaction_id: payload?.identifier,
    shipping: formatValueToStandardUnit(payload?.paymentBreakdown?.deliveryFee),
    discount: formatValueToStandardUnit(payload?.paymentBreakdown?.discount),
    email: payload?.contactEmail,
    coupon: payload?.promoCode,
    tax: formatValueToStandardUnit(payload?.paymentBreakdown?.tax),
  };
  window.gtag("event", "purchase", gTagPayload);
};

const track = (eventName, data) => {
  if (typeof gTag[eventName] === "function") {
    gTag[eventName](data);
  }
};

const gTag = {
  signUp,
  login,
  addToCart,
  viewCart,
  removeFromCart,
  beginCheckout,
  addPaymentInfo,
  purchase,
  track,
};

export default gTag;
