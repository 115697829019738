import React from "react";

import CheckCircleIcon from "@heroicons/react/outline/CheckCircleIcon";
import ExclamationCircleIcon from "@heroicons/react/outline/ExclamationCircleIcon";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import PropTypes from "prop-types";

import Button from "@/common/Button";

import AntModal from "./AntModal";

import "./Modal.less";

Modal.propTypes = {
  /** Whether the modal dialog is visible or not */
  visible: PropTypes.bool,
  /** Title to display in the modal. If there are no body text to display, you can pass this as `children`. */
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Icon to display beside copy (above for smaller screens). Presets include `error`, `warning`, `info`, and `success`. */
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Whether a close (x) button is visible on top right of the modal dialog or not */
  closable: PropTypes.bool,
  mobileClosable: PropTypes.bool,
  /** Whether to close the modal dialog when the mask (area outside the modal) is clicked */
  maskClosable: PropTypes.bool,
  /** Width of the modal dialog */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Footer content, set as `footer={null}` when you don't need default buttons */
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Text of the OK button */
  okText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Text of the Cancel button */
  cancelText: PropTypes.string,
  /** Button type of the OK button */
  okType: PropTypes.string,
  /** Specify a function that will be called when the user clicks the OK button */
  onOk: PropTypes.func,
  /** Whether the modal should show cancel button or not */
  noCancel: PropTypes.bool,
  /** Specify a function that will be called when the user clicks the Cancel button */
  onCancel: PropTypes.func,
  /** Body text to display in the modal. If `title` is not specified, this will be displayed as the title instead. */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  padding: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  antModalClassName: PropTypes.string,
  className: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Any other events and properties to be passed to the (antd) modal instance. */
  rest: PropTypes.any,
};

Modal.defaultProps = {
  visible: false,
  closable: true,
  mobileClosable: false,
  maskClosable: true,
  width: "auto",
  footer: "default",
  okText: "OK",
  cancelText: "Cancel",
  okType: "primary",
  noCancel: false,
  padding: true,
};

export default function Modal({
  visible,
  title,
  icon,
  closable,
  mobileClosable,
  maskClosable,
  width,
  footer,
  okText,
  cancelText,
  okType,
  onOk,
  noCancel,
  onCancel,
  children,
  padding,
  wrapperClassName,
  antModalClassName,
  className,
  wrapperStyle,
  style,
  ...rest
}) {
  let antModalKlass = "sm:max-w-2xl sm:w-full";
  let modalKlass =
    "inline-block align-bottom bg-default text-left overflow-hidden shadow-xl w-full transform transition-all sm:align-middle flex flex-col";
  if (padding) {
    modalKlass += " px-4 pt-5 pb-4 sm:p-6";
  }
  let titleBodyContainerKlass =
    "modal-title-body-container sm:w-full text-center sm:text-left flex-grow flex flex-col";
  let bodyContainerKlass = "flex-1 flex-grow";

  if (antModalClassName) {
    antModalKlass += ` ${antModalClassName}`;
  }
  if (className) {
    modalKlass += ` ${className}`;
  }
  if (title) {
    bodyContainerKlass += " mt-2";
  }
  if (icon) {
    titleBodyContainerKlass += " mt-3 sm:mt-0 sm:ml-4";
  }

  function getIconDisplay() {
    switch (icon) {
      case "success":
        return (
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-green-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <CheckCircleIcon className="w-6 h-6 text-success" />
          </div>
        );
      case "error":
        return (
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="w-6 h-6 text-danger" />
          </div>
        );
      case "warning":
        return (
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-yellow-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon className="w-6 h-6 text-yellow-600" />
          </div>
        );
      case "info":
        return (
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <InformationCircleIcon className="w-6 h-6 text-blue-600" />
          </div>
        );
    }
    return icon;
  }

  return (
    <AntModal
      visible={visible}
      transitionName=""
      footer={null}
      closable={false}
      maskClosable={maskClosable}
      width={width}
      onCancel={onCancel}
      className={antModalKlass}
      wrapClassName={wrapperClassName}
      style={wrapperStyle}
      {...rest}
    >
      <div
        className={modalKlass}
        style={style}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          className={`absolute top-0 right-0 pt-4 pr-4 z-10 ${
            mobileClosable ? "" : "hidden sm:block"
          }`}
        >
          {closable && (
            <button
              type="button"
              className="p-1 rounded-full bg-default text-default2 hover:text-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              onClick={onCancel}
            >
              <span className="sr-only">Close</span>
              <XIcon className="w-6 h-6" />
            </button>
          )}
        </div>
        <div className="flex flex-grow sm:items-start">
          {icon && getIconDisplay()}
          {/* show `children` as title if no `title` is specified */}
          <div className={titleBodyContainerKlass}>
            {title && (
              <h3
                className="text-lg font-medium leading-6 text-default"
                id="modal-headline"
              >
                {title}
              </h3>
            )}
            {children && (
              <div className={bodyContainerKlass}>
                <div className="text-sm text-default">{children}</div>
              </div>
            )}
          </div>
        </div>

        {footer && (
          <div>
            {/* render footer, if provided */}
            {footer !== "default" && footer}
            {/* render default footer otherwise */}
            {footer === "default" && (
              <div className="grid gap-3 mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  className="w-full sm:w-auto"
                  type={okType}
                  onClick={onOk}
                >
                  {okText}
                </Button>
                {!noCancel && (
                  <Button className="w-full sm:w-auto" onClick={onCancel}>
                    {cancelText}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </AntModal>
  );
}
