import { gql } from "@apollo/client";

export const PROFILE_QUERY = gql`
  query GetProfile {
    profile {
      name
      email
      mobileNumber
      isMobileVerified
      referralCode
      title
      dateOfBirth
      marketingConsent
      metadata
    }
  }
`;
