import React, { useState, useEffect, useContext, useRef, useMemo } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import unionBy from "lodash/unionBy";
import { useNavigate, useParams } from "react-router-dom";

import LandingPageBanner from "@/common/LandingPageBanner/LandingPageBanner";
import Layout from "@/common/Layout";
import Banner from "@/components/Banner";
import Cart from "@/components/Cart";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import FulfilmentSelectorModal from "@/components/FulfilmentSelector/FulfilmentSelectorModal";
import TimeslotPickerModal from "@/components/Menu/Timeslots/TimeslotPickerModal";
import { MenuProvider } from "@/components/Menu/menuContext";
import { ITEM_QUERY } from "@/graphql/queries/getItem";
import { OPTIMISED_MENU_QUERY } from "@/graphql/queries/getMenu";
import { PROFILE_QUERY } from "@/graphql/queries/getProfile";
import useAuth from "@/hooks/useAuth";
import { useListenEvent } from "@/utils/eventBus";
import { importGooglePlacesScript } from "@/utils/importGooglePlacesScript";
import useEberWidget from "@/utils/useEberWidget";

import CheckoutModal from "./CheckoutModal";
import MenuArea from "./MenuArea";
import MultiBrandNavigation from "./MultiBrandNavigation";
import RemovedItemsModal from "../FulfilmentSelector/RemovedItemsModal";

export default function Menu() {
  const { itemSlug, sectionSlug, tabSlug } = useParams();

  const navigate = useNavigate();

  const menuBanner = window?.dinerManifest?.banners?.["menu"];

  const { cartQuery, cartLoading, setOutletLoading } = useContext(CartContext);
  const { configQuery, configLoading, pathConfig } = useContext(ConfigContext);

  // only check for tabId if multi-brand
  const tabId = useMemo(
    () =>
      tabSlug && configQuery?.brands?.length > 0
        ? parseInt(tabSlug.split("-")[0])
        : null,
    [tabSlug, configQuery?.brands],
  );
  // prevent loading of brands outside of configuration
  const brandId = useMemo(
    () => tabId && configQuery?.brands?.find((brand) => brand.id === tabId)?.id,
    [tabId, configQuery?.brands],
  );

  const [orderProcessing, setOrderProcessing] = useState(null);
  const menuSectionRefs = useRef([]);
  const [activeBrandId, setActiveBrandId] = useState(
    brandId ?? configQuery?.brands[0]?.id ?? null,
  );
  const [combinedSections, setCombinedSections] = useState([]);
  const [menu, setMenu] = useState(null);

  const [showRemovedItemsModal, setShowRemovedItemsModal] = useState(false);
  const [removedItems, setRemovedItems] = useState([]);

  const scrollRef = useRef();

  const { loggedIn } = useAuth();

  const [getDetailedItem, { previousData: previousDetailedItemData }] =
    useLazyQuery(ITEM_QUERY, {
      context: { graph: "diners" },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // set selected item only on first call
        if (!previousDetailedItemData) {
          setSelectedItem({
            ...data.getItem,
          });
        }
      },
    });

  const { data: profileData } = useQuery(PROFILE_QUERY, {
    skip: !loggedIn(),
    context: { graph: "diners" },
  });

  const [
    getMenuData,
    {
      data,
      refetch: refetchMenuData,
      called: menuDataCalled,
      loading: menuLoading,
      error: menuError,
      previousData,
    },
  ] = useLazyQuery(OPTIMISED_MENU_QUERY, {
    fetchPolicy: "cache-first",
    context: { graph: "diners" },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMenu(data["getMenuOptimised"]);
    },
  });

  const [selectedCartItem, setSelectedCartItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [humanizedLeadTime, setHumanizedLeadTime] = useState("");
  const [humanizedAvailability, setHumanizedAvailability] = useState("");
  const [menuSectionDisabledReason, setMenuSectionDisabledReason] =
    useState("");
  const [selectedSectionId, setSelectedSectionId] = useState(
    menu?.sections[0]?.id,
  );

  useEffect(() => {
    // on load configQuery, set default active brand
    if (configQuery) {
      setActiveBrandId(brandId ?? configQuery?.brands[0]?.id ?? activeBrandId);
    }
  }, [configQuery]);

  useEffect(() => {
    // append tab slug to url, if it exists at first
    if (tabSlug && activeBrandId && pathConfig?.menuPath) {
      navigate(`${pathConfig?.menuPath}tab/${activeBrandId}`, {
        replace: true,
      });
    }
  }, [activeBrandId]);

  useEffect(() => {
    // delay and open up item details
    if (menu && itemSlug) {
      const itemId = itemSlug.split("-")[0];
      const item = findItemById(parseInt(itemId), menu.sections);

      if (item) {
        setTimeout(() => {
          setSelectedItem(item);
        }, 200);
      } else if (!previousData && configQuery?.brands?.length > 0) {
        // only get detailed items if multi-brand and previousData is unavailable

        // if previousData is available, this is likely caused by loading a new menu,
        // but selecting an item from the previous menu before the new menu is loaded
        // if that is the case, don't get detailed item, as it is meant to be unavailable

        getDetailedItem({
          variables: { itemId: parseInt(itemId) },
        });
      }
    } else if (!itemSlug) {
      setSelectedItem(null);
    }

    if (!selectedSectionId) {
      setSelectedSectionId(menu?.sections[0]?.id);
    }
  }, [JSON.stringify(menu), itemSlug]);

  useEffect(() => {
    // scroll to section based on slug
    if (menu && sectionSlug && menuSectionRefs.current && scrollRef.current) {
      const sectionId = sectionSlug.split("-")[0];

      const section = findSectionById(
        parseInt(sectionId),
        data.getMenuOptimised?.sections,
      );

      if (section) {
        // section needs time to render and appear in the ref, will need a more elegant solution for this
        setTimeout(() => {
          const element = menuSectionRefs.current[sectionId];

          if (element) {
            // not sure why, but without next tick, it scrolls to a slightly off position
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 200);
      }
    }
  }, [
    JSON.stringify(menu),
    sectionSlug,
    menuSectionRefs.current,
    scrollRef.current,
  ]);

  useEffect(() => {
    return importGooglePlacesScript();
  }, []);

  // Load Eber Widget
  useEberWidget({ configQuery, profileData });

  useEffect(() => {
    if (menu?.sections && menu.sections.length > 0) {
      setCombinedSections(unionBy(combinedSections, menu.sections, "id"));
    }
  }, [JSON.stringify(menu)]);

  useListenEvent("editCartItem", (cartItem) => {
    setSelectedCartItem(cartItem);
    setSelectedItem(findItemById(cartItem.item.id, combinedSections));
  });

  useListenEvent("showItemDetails", (item) => {
    setSelectedItem(item);
  });

  useListenEvent("showRemovedItemsModal", (items) => {
    if (items && items.length > 0) {
      setRemovedItems(items);
      setShowRemovedItemsModal(true);
    }
  });

  function findItemById(itemId, sections) {
    let targetItem;
    sections.some((section) => {
      if (section.subSections?.length) {
        targetItem = findItemById(itemId, section.subSections) || null;
        return !!targetItem;
      }
      targetItem = section.items.find((item) => item.id === itemId);
      return !!targetItem;
    });
    return targetItem;
  }

  function findSectionById(sectionId, sections) {
    let targetSection;
    sections.some((section) => {
      if (section.id === sectionId) {
        targetSection = section;
        return true;
      } else if (section.subSections?.length) {
        targetSection = findSectionById(sectionId, section.subSections);
        return !!targetSection;
      }
    });

    return targetSection;
  }

  function showPreOrderModal(humanizedLeadTime) {
    setHumanizedLeadTime(humanizedLeadTime);
  }

  function showAvailabilityModal(humanizedAvailability) {
    setHumanizedAvailability(humanizedAvailability);
  }

  function showMenuSectionDisabledModal(menuSectionDisabledReason) {
    setMenuSectionDisabledReason(menuSectionDisabledReason);
  }

  useEffect(() => {
    function fetchMenuAndMenuAvailability(options) {
      if (cartQuery?.cart?.servingDate) {
        const variables = {
          cartId: cartQuery.cart.id,
          brandId: activeBrandId,
          fulfilmentType: cartQuery.cart.fulfilmentType,
          outletId: cartQuery.cart.outletId,
          servingDate: cartQuery.cart.servingDate,
          timeslot: cartQuery.cart?.timeslot
            ? [
                cartQuery.cart.timeslot.startTime,
                cartQuery.cart.timeslot.endTime,
              ]
            : null,
        };

        const cartDate = dayjs(cartQuery.cart.servingDate);

        if (menuDataCalled) {
          if (
            options?.forceFetchMenu ||
            !cartDate.isBetween(menu?.startDate, menu?.endDate)
          ) {
            refetchMenuData(variables);
          }
        } else {
          getMenuData({ variables });
        }
      }
    }

    fetchMenuAndMenuAvailability({ forceFetchMenu: true });
  }, [
    cartQuery?.cart?.servingDate,
    // refetch menu on change timeslot for availability conditions with timeslot_ranges
    cartQuery?.cart?.timeslot?.id,
    // refetch menu on change fulfilment_type for availability conditions based on fulfilment_types
    cartQuery?.cart?.fulfilmentType,
    // refetch on outletId change for menus that are specific to an outlet
    cartQuery?.cart?.outletId,
    configQuery?.brands,
    activeBrandId,
  ]);

  return (
    <MenuProvider
      value={{ activeBrandId, menu: menu || previousData?.getMenuOptimised }}
    >
      <Layout
        fullscreen
        isTransparentHeader={window.dinerManifest?.transparentHeaderAtTopOfMenu}
        headerCart={
          <Cart
            onOrderProcessing={(data) => {
              setOrderProcessing(data);
            }}
          />
        }
        addressModal={<FulfilmentSelectorModal />}
        timeslotModal={<TimeslotPickerModal />}
        scrollRef={scrollRef}
      >
        {menuBanner && <Banner data={menuBanner} />}
        <LandingPageBanner />
        {configQuery?.brands.length > 0 && (
          <MultiBrandNavigation
            brands={configQuery?.brands}
            setActiveBrandId={setActiveBrandId}
            activeBrandId={activeBrandId}
          />
        )}
        <MenuArea
          menuData={menu || previousData?.getMenuOptimised}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectedCartItem={selectedCartItem}
          setSelectedCartItem={setSelectedCartItem}
          setSelectedSectionId={setSelectedSectionId}
          humanizedLeadTime={humanizedLeadTime}
          setHumanizedLeadTime={setHumanizedLeadTime}
          humanizedAvailability={humanizedAvailability}
          setHumanizedAvailability={setHumanizedAvailability}
          setMenuSectionDisabledReason={setMenuSectionDisabledReason}
          menuSectionDisabledReason={menuSectionDisabledReason}
          showPreOrderModal={showPreOrderModal}
          showAvailabilityModal={showAvailabilityModal}
          showMenuSectionDisabledModal={showMenuSectionDisabledModal}
          menuError={menuError}
          // only show menu as loading if cart has not been loaded yet, or if menu is loading
          loading={
            (cartLoading && !cartQuery) ||
            configLoading ||
            setOutletLoading ||
            menuLoading
          }
          menuSectionRefs={menuSectionRefs}
        />
        <CheckoutModal
          orderProcessing={orderProcessing}
          setOrderProcessing={setOrderProcessing}
        />
        {showRemovedItemsModal && (
          <RemovedItemsModal
            items={removedItems}
            onClose={() => setShowRemovedItemsModal(false)}
          />
        )}
      </Layout>
    </MenuProvider>
  );
}
