import React, { useState, useEffect } from "react";

import { Transition } from "@headlessui/react";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import classNames from "classnames";
import dayjs from "dayjs";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAnalytics from "@/hooks/useAnalytics";
import useTranslatedText from "@/hooks/useTranslationText";
import constants from "@/utils/constants";
import formatPrice from "@/utils/formatPrice";

import OrderHistoryItem from "./OrderHistoryItem";
import OrderStatus from "./OrderStatus";

OrderHistory.propTypes = {
  order: PropTypes.object,
  track: PropTypes.bool,
  isConfirmationScreen: PropTypes.bool,
  isCart: PropTypes.bool,
};

OrderHistory.defaultProps = {
  isConfirmationScreen: false,
  isCart: false,
};

export default function OrderHistory({
  order,
  track,
  isConfirmationScreen,
  isCart,
}) {
  const { t } = useTranslation();
  const { analytics, events } = useAnalytics();

  const formattedServingDate = dayjs(order.servingDate).format("MMM D YYYY");
  const formattedInvoiceDate = dayjs(order.invoiceDate).format("MMM D YYYY");
  const formattedSubtotal = formatPrice(order.paymentBreakdown.subtotal);
  const formattedTotalPrice = formatPrice(
    order.paymentBreakdown.totalIncludingTax,
  );
  const formattedDeliveryFee =
    order.paymentBreakdown.deliveryFee > 0
      ? formatPrice(order.paymentBreakdown.deliveryFee)
      : t("account.orders.freeDelivery");
  const formattedSurcharge = formatPrice(order.paymentBreakdown.surcharge);
  const formattedDonationAmount = formatPrice(
    order.paymentBreakdown.donationAmount,
  );
  const formattedDiscount = `–${formatPrice(order.paymentBreakdown.discount)}`;
  const formattedServiceCharge = formatPrice(
    order.paymentBreakdown.serviceCharge,
  );
  const formattedTax = formatPrice(order.paymentBreakdown.tax);
  const formattedAddress = getFormattedAddress();
  const timeslotRangeLabel = isCart
    ? order.timeslot?.rangeLabel
    : order.timeslotRangeLabel;
  const orderName = useTranslatedText({
    resource: order?.orderItems,
    fallbackValue: (item) => item?.name,
  }).join(", ");
  const orderItems = order?.orderItems?.map((orderItem, index) => (
    <OrderHistoryItem key={index} orderItem={orderItem} />
  ));

  const multiBrandedOrderItems = map(
    groupBy(order?.orderItems, "brand.label"),
    (sortedOrderItems, brand) => (
      <div key={brand}>
        <div className="mt-2 mb-3 border-b-2 font-display first:mt-0 border-primary">
          {brand}
        </div>
        {sortedOrderItems.map((orderItem, index) => (
          <OrderHistoryItem key={index} orderItem={orderItem} />
        ))}
      </div>
    ),
  );

  const [receiptOpened, setReceiptOpened] = useState(isConfirmationScreen);

  useEffect(() => {
    if (receiptOpened) {
      analytics.track(events.order_receipt_viewed);
    }
  }, [receiptOpened]);

  function getFormattedAddress() {
    switch (order.fulfilmentType) {
      case constants.FULFILMENT_TYPES.PICKUP:
        return t("account.orders.pickupLabel", {
          labelForPickup: order.outlet.labelForPickup,
        });
      case constants.FULFILMENT_TYPES.DINE_IN:
        return t("account.orders.dineInLabel", {
          labelForPickup: order.outlet.labelForPickup,
        });
    }
    return isCart
      ? `${order.address?.line1} ${order.address?.line2}`
      : `${order.addressLine1} ${order?.addressLine2}`;
  }

  function renderDiscountRow() {
    return (
      order.paymentBreakdown?.discount > 0 && (
        <div className="flex">
          <div className="flex-grow">{t("account.orders.discount")}</div>
          <div>{formattedDiscount}</div>
        </div>
      )
    );
  }

  return (
    <div className="pb-6 mb-6 overflow-hidden shadow bg-default2">
      <ul className="mx-6 mb-0 border-b border-gray-500 divide-y divide-gray-500">
        {!isConfirmationScreen && (
          <>
            <li className="py-4">
              <div className="pb-4 text-sm font-bold truncate text-default">
                {orderName}
              </div>
              <div
                className={classNames(
                  "pb-4 text-sm font-medium text-default",
                  order.state === constants.ORDER_STATUSES.CANCELLED &&
                    "line-through",
                )}
              >
                {t(
                  `account.orders.${
                    !track || order.state === constants.ORDER_STATUSES.COMPLETED
                      ? "servingDateCompletedLabel"
                      : "servingDateLabel"
                  }`,
                  {
                    servingDate: formattedServingDate,
                    servingTime: timeslotRangeLabel,
                  },
                )}
              </div>
              {order.state === constants.ORDER_STATUSES.CANCELLED && (
                <div className="pb-4 text-sm italic font-medium text-default">
                  Order cancelled
                </div>
              )}
              <div className="text-sm font-medium text-default">
                {formattedTotalPrice}
              </div>
            </li>
            <OrderStatus
              track={track}
              order={order}
              formattedServingDate={formattedServingDate}
            />
          </>
        )}
        <li className="py-4">
          <div
            className="flex items-center text-sm font-bold cursor-pointer text-default"
            onClick={() => setReceiptOpened((receiptOpened) => !receiptOpened)}
          >
            <div className="flex-grow mr-2">
              {isCart
                ? t("menu.checkoutModal.processing.header")
                : t("account.orders.identifier", {
                    identifier: order.identifier,
                  })}
            </div>
            <ChevronRightIcon
              className={classNames([
                "h-4 w-4 transform transition-transform ease-in-out",
                {
                  "rotate-90": receiptOpened,
                },
              ])}
            />
          </div>
          <Transition
            show={receiptOpened}
            enter="transition-opacity duration-75"
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave="transition-opacity duration-150"
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}
          >
            {order.state === constants.ORDER_STATUSES.COMPLETED && (
              <div>
                <CheckCircleIcon className="mx-auto mb-3 h-14 w-14 text-success" />
                <p className="text-center">
                  <strong>
                    {t("account.orders.successfullyDelivered", {
                      servingDate: formattedServingDate,
                    })}
                  </strong>
                </p>
              </div>
            )}
            <ul className="mb-0 divide-y divide-gray-500">
              <li className="py-3 text-sm font-medium">
                <div className="font-bold">
                  <span>
                    {order.recipientName && order.recipientContactNumber
                      ? `${order.recipientName} (${order.recipientContactNumber})`
                      : `${order.contactName} (${order.contactNumber})`}
                  </span>
                </div>
                {order.fulfilmentType ===
                  constants.FULFILMENT_TYPES.DELIVERY && (
                  <div>{formattedAddress}</div>
                )}
              </li>
              {order.giftMessage != null && (
                <li className="py-3 text-sm font-medium">
                  <div className="font-bold">
                    {t("menu.cart.checkout.labels.giftMessage")}
                  </div>
                  <div>{order.giftMessage}</div>
                </li>
              )}
              <li className="py-3 text-sm font-medium">
                <span className="font-bold">
                  {t("account.orders.servingDate", {
                    servingDate: formattedServingDate,
                    servingTime: timeslotRangeLabel,
                  })}
                </span>
                {order.fulfilmentType ===
                  constants.FULFILMENT_TYPES.DELIVERY && (
                  <div>
                    <span>
                      {t("account.orders.fulfilledByLabel", {
                        outlet: order.outlet.labelForPickup,
                      })}
                    </span>
                    {!isCart && !!order.outlet.contactNumber && (
                      <>
                        {" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "account.orders.contactNumberLabelDelivery",
                              {
                                contactNumberNoSpaces:
                                  order.outlet.contactNumber.replaceAll(
                                    " ",
                                    "",
                                  ),
                                contactNumber: order.outlet.contactNumber,
                              },
                            ),
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
                {!isCart &&
                  !!order.outlet.contactNumber &&
                  order.fulfilmentType ===
                    constants.FULFILMENT_TYPES.PICKUP && (
                    <div>
                      <span>{formattedAddress}</span>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "account.orders.contactNumberLabelDelivery",
                            {
                              contactNumberNoSpaces:
                                order.outlet.contactNumber.replaceAll(" ", ""),
                              contactNumber: order.outlet.contactNumber,
                            },
                          ),
                        }}
                      />
                    </div>
                  )}
              </li>
              <li className="py-3 space-y-4 text-sm font-medium">
                {order.brands.length > 0 ? multiBrandedOrderItems : orderItems}
              </li>
              <li className="py-3 space-y-3 text-sm font-medium">
                <div className="flex">
                  <div className="flex-grow">
                    {t("account.orders.subtotal")}
                  </div>
                  <div id="order-subtotal">{formattedSubtotal}</div>
                </div>
                {order.paymentBreakdown?.surcharge > 0 && (
                  <div className="flex">
                    <div className="flex-grow">
                      {order.paymentBreakdown?.surchargeLabel
                        ? `${order.paymentBreakdown.surchargeLabel}:`
                        : t("account.orders.surcharge")}
                    </div>
                    <div id="order-surcharge">{formattedSurcharge}</div>
                  </div>
                )}
                {/* render discount row here only if NOT post-tax discount */}
                {!order.paymentBreakdown.isPostTaxDiscount &&
                  renderDiscountRow()}
                {order.fulfilmentType ===
                  constants.FULFILMENT_TYPES.DELIVERY && (
                  <div className="flex">
                    <div className="flex-grow">
                      {t("account.orders.deliveryFee")}
                    </div>
                    <div id="order-delivery-fee">{formattedDeliveryFee}</div>
                  </div>
                )}
                {order.paymentBreakdown?.donationAmount > 0 && (
                  <div className="flex">
                    <div className="flex-grow">
                      {order.paymentBreakdown?.donationLabel
                        ? t("account.orders.donationLabel", {
                            donationLabel: order.paymentBreakdown.donationLabel,
                          })
                        : t("account.orders.donationAmount")}
                    </div>
                    <div id="order-donation-amount">
                      {formattedDonationAmount}
                    </div>
                  </div>
                )}
                {/* render service charge row here only if service charge exists */}
                {(order.paymentBreakdown?.serviceCharge &&
                  order.paymentBreakdown.serviceCharge) > 0 && (
                  <div className="flex">
                    <div className="flex-grow">
                      {t("account.orders.serviceCharge")}
                    </div>
                    <div id="order-service-charge">
                      {formattedServiceCharge}
                    </div>
                  </div>
                )}
                {/* render tax row here only if NOT tax inclusive */}
                {!order.paymentBreakdown?.taxInclusivePrices &&
                  order.paymentBreakdown?.tax > 0 && (
                    <div className="flex">
                      <div className="flex-grow">{t("account.orders.tax")}</div>
                      <div id="order-tax-payable">{formattedTax}</div>
                    </div>
                  )}
                {/* render discount row here only if post-tax discount */}
                {order.paymentBreakdown.isPostTaxDiscount &&
                  renderDiscountRow()}
                <div className="flex font-bold">
                  <div className="flex-grow">
                    {t("account.orders.totalPayable")}
                  </div>
                  <div id="order-total-payable">{formattedTotalPrice}</div>
                </div>
                {/* render tax row here only if tax inclusive */}
                {order.paymentBreakdown?.taxInclusivePrices &&
                  order.paymentBreakdown?.tax > 0 && (
                    <div className="flex">
                      <div className="flex-grow">
                        {t("account.orders.taxIncluded")}
                      </div>
                      <div id="order-tax-included">{formattedTax}</div>
                    </div>
                  )}
              </li>
              {!isCart && (
                <li>
                  <div className="flex">
                    <span className="flex-grow my-2 text-xs text-right">
                      {order.cardBrand &&
                        order.cardLast4 &&
                        t("account.orders.paidWithCard", {
                          cardBrandLabel: t(
                            `account.orders.cardBrand.${order.cardBrand}`,
                          ),
                          cardLast4: order.cardLast4,
                        })}
                    </span>
                  </div>
                  <div className="text-xs text-center text-default2">
                    <div>
                      {t("account.orders.invoiceDate", {
                        date: formattedInvoiceDate,
                      })}
                    </div>
                    <div>
                      {order.outlet.labelForPickup}, {order.outlet.address}
                    </div>
                    {order.outlet.uen && (
                      <div>
                        {t("account.orders.uen", {
                          uen: order.outlet.uen,
                        })}
                      </div>
                    )}
                    <div>
                      {
                        window?.dinerManifest?.businessInformation
                          ?.entityAddress
                      }
                    </div>
                    <div>
                      {window?.dinerManifest?.businessInformation?.entityGst}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </Transition>
        </li>
      </ul>
    </div>
  );
}
