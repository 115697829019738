import React from "react";

import ExclamationCircleIcon from "@heroicons/react/solid/ExclamationCircleIcon";
import generatePicker from "antd/lib/date-picker/generatePicker";
import dayjsGenerateConfig from "antd/node_modules/rc-picker/lib/generate/dayjs";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import PropTypes from "prop-types";

dayjs.extend(weekday);
dayjs.extend(localeData);

import Switch from "@/common/Switch";

const DatePicker = generatePicker(dayjsGenerateConfig);

import "./input.css";

Input.propTypes = {
  /** HTML type for input, eg. `text`, `email`, `number`, etc. */
  type: PropTypes.string,
  /** Label of the input. Leave blank to not show any label. */
  label: PropTypes.string,
  /** Description of the input. */
  description: PropTypes.string,
  /** Placeholder of input. */
  placeholder: PropTypes.string,
  /** Leading icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  leftIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Trailing icon in SVG/JSX format. See https://heroicons.com/ for list of icons. */
  rightIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  /** Corner hint that appears at the top right of the input. */
  hint: PropTypes.string,
  /** Text that appears below the input. Will be overwritten by `error` if present. */
  helpText: PropTypes.string,
  /** Error message to show below the input. Will overwrite `helpText`. */
  error: PropTypes.string,
  /** Class to pass to input. */
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  inputStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Element(s) to replace the input, usually a different type of input, eg. `DatePicker`, `Select`, etc. */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  focus: PropTypes.bool,
  isQuantityControl: PropTypes.bool,
  searchBar: PropTypes.bool,
  /** Any other properties or events that will be passed on to the input instance. */
  rest: PropTypes.any,
};

Input.defaultProps = {
  type: "text",
  focus: true,
  isQuantityControl: false,
};

export default function Input({
  type,
  label,
  description,
  placeholder,
  leftIcon,
  rightIcon,
  hint,
  helpText,
  error,
  inputClassName,
  className,
  inputStyle,
  style,
  children,
  focus,
  isQuantityControl,
  searchBar,
  ...rest
}) {
  const labelId = label ? label.toLowerCase().split(" ").join("-") : null;
  let inputContainerKlass = (label || hint) && type !== "switch" ? "mt-1" : "";
  if (leftIcon || rightIcon || error) {
    if (inputContainerKlass) inputContainerKlass += " ";
    inputContainerKlass += "relative rounded-md shadow-sm";
  }

  let inputKlass = "block sm:text-sm ";

  if (!inputClassName || !inputClassName?.includes("w-")) {
    inputKlass += "w-full ";
  }

  if (searchBar) {
    inputKlass += "rounded-t-sm border-b-0";
  } else {
    inputKlass += "rounded-sm";
  }

  if (isQuantityControl) {
    inputKlass +=
      " bg-default text-default border-default border-l-0 border-r-0 no-focus";
  } else {
    inputKlass += " bg-button-default text-on-button-default";
  }

  if (error) {
    inputKlass += " border-danger text-danger placeholder-danger ";
  } else {
    inputKlass += " shadow-sm !border-default";
  }
  if (focus) {
    if (error) {
      inputKlass += " focus:ring-danger";
    } else {
      inputKlass += " focus:ring-primary focus:border-primary";
    }
  } else {
    inputKlass += " focus:outline-none focus:border-none";
  }

  if (leftIcon) {
    inputKlass += " pl-4";
  } else if (rightIcon || error) {
    inputKlass += " pr-4";
  }

  if (rest.readOnly || rest.disabled) {
    inputKlass += " opacity-50";
  }
  if (inputClassName) {
    inputKlass += ` ${inputClassName}`;
  }

  function renderLabel() {
    if (label) {
      return (
        <label
          htmlFor={`${labelId}`}
          className="block text-xs font-semibold uppercase text-default"
          data-testid="input-label"
        >
          {label}
        </label>
      );
    }
    return null;
  }

  function renderInput() {
    switch (type) {
      case "textarea":
        return (
          <textarea
            name={labelId}
            id={labelId}
            className={inputKlass}
            style={inputStyle}
            placeholder={placeholder}
            {...rest}
          />
        );
      case "switch":
        return (
          <div className="flex space-x-6">
            {renderLabel()}
            <Switch isEnabled={rest.value} style={inputStyle} {...rest} />
          </div>
        );
      case "datePicker":
        return (
          <DatePicker
            className={inputKlass}
            style={inputStyle}
            placeholder={placeholder}
            {...rest}
          />
        );
      default:
        return (
          <input
            name={labelId}
            id={labelId}
            type={type}
            className={inputKlass}
            style={inputStyle}
            placeholder={placeholder}
            {...rest}
          />
        );
    }
  }

  return (
    <div className={className} style={style}>
      {(label || hint) && (
        <div className="flex justify-between">
          {type !== "switch" && renderLabel()}
          {hint && (
            <span
              className="text-xs font-semibold text-default3"
              data-testid="input-hint"
            >
              {hint}
            </span>
          )}
        </div>
      )}

      {description && (
        <p className="mt-2 text-sm text-gray-500 mb-4">{description}</p>
      )}

      <div className={inputContainerKlass}>
        {children}

        {/* default to input if no children is provided */}
        {!children && (
          <>
            {leftIcon && (
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                {leftIcon}
              </div>
            )}
            {renderInput()}
            {error && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <ExclamationCircleIcon className="w-5 h-5 text-danger" />
              </div>
            )}
            {!error && rightIcon && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                {rightIcon}
              </div>
            )}
          </>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-danger" data-testid="input-error">
          {error}
        </p>
      )}
      {/* don't show helpText if error is present */}
      {!error && helpText && (
        <p className="mt-2 text-sm text-default3" data-testid="input-help-text">
          {helpText}
        </p>
      )}
    </div>
  );
}
