import React, { useContext } from "react";

import LocationMarkerIcon from "@heroicons/react/solid/LocationMarkerIcon";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ConfigContext from "@/components/Config/configContext";

SelectedAddressLabel.propTypes = {
  label: PropTypes.node,
  isTransparent: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  rest: PropTypes.any,
};

export default function SelectedAddressLabel({
  label,
  isTransparent,
  className,
  readOnly,
  ...rest
}) {
  const { t } = useTranslation();
  const { outletQrHash } = useContext(ConfigContext);

  return (
    <div>
      <div className="text-base lg:hidden">
        {outletQrHash ? t("navbar.qrOutletLabel") : t("navbar.addressLabel")}
      </div>
      <div
        className={classNames([
          "flex items-center py-2 px-1 cursor-pointer",
          isTransparent
            ? "text-on-transparent-header border-on-transparent-header"
            : "border-on-header",
          className ?? "",
          { "border-b-2": !readOnly },
        ])}
        {...rest}
      >
        <div className="mr-2">
          <LocationMarkerIcon
            className={`w-5 h-5 ${
              isTransparent ? "text-on-transparent-header" : "text-primary"
            }`}
          />
        </div>
        <div
          className={`truncate ${
            isTransparent ? "text-on-transparent-header" : "text-on-header"
          }`}
        >
          {label}
        </div>
      </div>
    </div>
  );
}
