import React, { useState, useEffect, useRef, useContext, useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Scrollspy from "react-scrollspy";

import ScrollContext from "@/common/Layout/scrollContext";
import useAnalytics from "@/hooks/useAnalytics";
import useTranslatedText from "@/hooks/useTranslationText";
import constants, { LG_BREAKPOINT } from "@/utils/constants";

import MenuContext from "./menuContext";
import ConfigContext from "../Config/configContext";

Sidebar.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  menuSectionRefs: PropTypes.object,
  onSectionSelect: PropTypes.func,
  affixed: PropTypes.bool,
};

const PADDING = 24;
const HEADER_HEIGHT = 64;
const LG_SCROLL_OFFSET = 0 - HEADER_HEIGHT - PADDING;
const MOBILE_SCROLL_OFFSET = 0 - HEADER_HEIGHT - PADDING;

const WINDOW_HEIGHT_SCROLL_DIVISOR = 977;
const MIN_SCROLL_DURATION = 500;

export default function Sidebar({
  sections,
  menuSectionRefs,
  onSectionSelect,
  affixed,
}) {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubSection, setSelectedSubSection] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const { analytics, events } = useAnalytics();

  const sidebarScroller = useRef(null);
  const sidebarSectionRefs = useRef([]);
  const sidebarSubSectionRefs = useRef({});

  const scroller = useContext(ScrollContext);
  const { menu } = useContext(MenuContext);

  function setSectionFromElementId(elementId) {
    const sectionId = parseInt(elementId.replace("menu_section_", ""));
    const section = sections.find((section) => {
      setSelectedSubSection(null);
      if (section.id === sectionId) return true;
      return section.subSections.find((subSection) => {
        if (subSection.id === sectionId) {
          setSelectedSubSection(subSection);
          return true;
        }
        return false;
      });
    });
    setSelectedSection(
      selectedSubSection ? { ...section, selectedSubSection } : section,
    );
  }

  useEffect(() => {
    if (selectedSection) {
      analytics.trackEventWithProperties(
        events.product_list_viewed,
        selectedSection,
      );
      onSectionSelect(selectedSection?.id);
    }
  }, [selectedSection]);

  function scrollMenuTo(sectionId) {
    const element = menuSectionRefs.current[sectionId];
    if (element && !isScrolling) {
      const htmlElement = scroller.current;
      const offsetValue =
        window.innerWidth >= LG_BREAKPOINT
          ? LG_SCROLL_OFFSET
          : MOBILE_SCROLL_OFFSET;

      const posY = element.offsetTop + offsetValue;
      const scrollDuration =
        Math.abs(posY - htmlElement.scrollTop) /
          (htmlElement.scrollHeight / WINDOW_HEIGHT_SCROLL_DIVISOR) +
        MIN_SCROLL_DURATION;

      setIsScrolling(true);

      // not sure why, but without next tick, it scrolls to a slightly off position
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => setIsScrolling(false), scrollDuration);
      }, 0);
    }
  }

  useEffect(() => {
    if (
      selectedSubSection?.id &&
      window.innerWidth < LG_BREAKPOINT &&
      sidebarSubSectionRefs?.current[selectedSubSection.id]
    ) {
      setIsScrolling(true);
      sidebarSubSectionRefs.current[selectedSubSection.id].scrollIntoView({
        behaviour: "smooth",
        block: "end",
        inline: "center",
      });
      setTimeout(() => setIsScrolling(false), 100);
    }
  }, [selectedSubSection, sidebarSubSectionRefs]);

  function renderSubSection({ className = "", floating = false }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (
        selectedSubSection?.id &&
        window.innerWidth < LG_BREAKPOINT &&
        sidebarSubSectionRefs?.current[selectedSubSection.id]
      ) {
        setIsScrolling(true);
        sidebarSubSectionRefs.current[selectedSubSection.id].scrollIntoView({
          behaviour: "smooth",
          block: "end",
          inline: "center",
        });
        setTimeout(() => setIsScrolling(false), 100);
      }
    }, [selectedSubSection, sidebarSubSectionRefs]);

    return (
      selectedSection?.subSections?.length > 0 && (
        <div
          className={`flex lg:hidden overflow-x-scroll no-scrollbar bg-default ${
            floating ? "py-1 mx-auto rounded-full opacity-90" : "py-3"
          } ${className || ""}`}
          style={floating ? { maxWidth: "100%", width: "fit-content" } : {}}
          id="sub-menu-mobile-1"
        >
          {selectedSection.subSections.map((subSection, index) => (
            <MobileSubSection
              key={`mobile-${subSection.label}-${index}`}
              subSectionRef={(el) =>
                floating && (sidebarSubSectionRefs.current[subSection.id] = el)
              }
              setIsScrolling={setIsScrolling}
              selectedSubSectionId={selectedSubSection?.id}
              subSection={subSection}
              onClick={() => {
                setSelectedSubSection(subSection);
                return setTimeout(() => {
                  scrollMenuTo(subSection.id);
                  sidebarSubSectionRefs.current[subSection.id].scrollIntoView({
                    behaviour: "smooth",
                    block: "end",
                    inline: "center",
                  });
                }, 0);
              }}
            />
          ))}
        </div>
      )
    );
  }

  const menuLabel = useTranslatedText({
    resource: menu,
    fallbackValue: t("menu.sidebar.header"),
  });

  return (
    <div data-testid="menu-sidebar">
      <div
        className={classNames([
          "flex flex-col flex-grow bg-default lg:bg-transparent transition-all lg:overflow-y-auto",
          {
            "pt-6 sm:pt-12 pb-4": !affixed,
            "pt-1 pb-2 z-20 shadow-inner lg:shadow-none": affixed,
          },
        ])}
      >
        <div className="flex flex-col flex-grow">
          <nav className="relative flex-1" aria-label="Sidebar">
            <div
              className={classNames([
                "lg:mb-5 transition-all ease-out text-2xl md:text-3xl font-display text-center lg:text-left",
                {
                  "opacity-100": !affixed,
                  "h-0 pointer-events-none opacity-0": affixed,
                },
              ])}
            >
              {menuLabel}
            </div>
            <div
              className={classNames(
                "lg:hidden absolute bottom-[48px] w-full",
                affixed ? "opacity-100" : "opacity-0",
                affixed && "mobile-sub-sections-floating-nav",
              )}
            >
              {renderSubSection({ floating: true })}
            </div>
            {sections.length > 0 && (
              <div
                id="sidebar-scroller"
                ref={sidebarScroller}
                className={classNames([
                  "overflow-x-scroll no-scrollbar bg-default",
                  isScrolling && "opacity-60",
                  !affixed && "sm:ml-0 -ml-3 sm:mr-0 -mr-3",
                ])}
              >
                <Scrollspy
                  rootEl="#scroller"
                  componentTag="div"
                  className="flex justify-center min-w-max lg:min-w-0 lg:block"
                  // the detection is sometimes off slightly, so modify by -1
                  offset={-41} // For accurately showing sidebar when accessing from slug
                  items={sections.flatMap((section) =>
                    [`menu_section_${section.id}`].concat(
                      section.subSections.map(
                        (subSection) => `menu_section_${subSection.id}`,
                      ),
                    ),
                  )}
                  onUpdate={(element) => {
                    if (element && !isScrolling) {
                      setSectionFromElementId(element.id);
                      const sidebarRefIndex = parseInt(
                        element.getAttribute("data-sidebar-ref-index"),
                      );
                      if (
                        !isNaN(sidebarRefIndex) &&
                        window.innerWidth < LG_BREAKPOINT &&
                        sidebarSectionRefs?.current &&
                        affixed
                      ) {
                        sidebarSectionRefs.current[
                          sidebarRefIndex
                        ].scrollIntoView({
                          behaviour: "smooth",
                          block: "end",
                          inline: "center",
                        });
                      }
                    }
                  }}
                >
                  {sections.map((section, index) => (
                    <SidebarSection
                      key={`${section.label}-${index}`}
                      sectionRef={(el) =>
                        (sidebarSectionRefs.current[index] = el)
                      }
                      section={section}
                      selectedSection={selectedSection}
                      onClick={(section) => {
                        if (isScrolling) return;
                        setSelectedSection(section);
                        analytics.trackEventWithProperties(
                          events.product_list_filtered,
                          section,
                        );
                        return setTimeout(() => {
                          if (
                            sidebarScroller?.current &&
                            sidebarSectionRefs?.current
                          ) {
                            sidebarSectionRefs.current[index].scrollIntoView({
                              behavior: "smooth",
                              block: "end",
                              inline: "center",
                            });
                          }

                          scrollMenuTo(section.id);
                        }, 0);
                      }}
                    />
                  ))}
                </Scrollspy>
                {renderSubSection({ className: "hidden lg:block" })}
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}

MobileSubSection.propTypes = {
  selectedSubSectionId: PropTypes.number,
  subSection: PropTypes.object,
  onClick: PropTypes.func,
  subSectionRef: PropTypes.func,
};

function MobileSubSection({
  selectedSubSectionId,
  subSection,
  onClick,
  subSectionRef,
}) {
  const subSectionLabel = useTranslatedText({
    resource: subSection,
    fallbackValue: subSection?.label,
  });

  return (
    <a
      ref={subSectionRef}
      className={classNames([
        "px-4 py-1 mx-1 w-max flex-nowrap text-xs text-default hover:text-primary",
        subSection.id === selectedSubSectionId &&
          "font-bold text-on-primary hover:text-on-primary rounded-full bg-primary",
      ])}
      style={{
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
    >
      {subSectionLabel}
    </a>
  );
}

SidebarSection.propTypes = {
  section: PropTypes.object,
  selectedSection: PropTypes.object,
  sectionRef: PropTypes.func,
  onClick: PropTypes.func,
};

function getSectionClass(isSelected) {
  const defaultClassName =
    "group w-full lg:border-l-2 lg:flex items-center text-center lg:text-left p-2 text-sm font-medium focus:color-primary";
  // Current: "bg-faded text-default"
  // Default: "bg-default text-default2 hover:bg-faded hover:text-default"
  if (isSelected) {
    return `border-b-2 lg:border-b-0 border-primary text-default font-bold hover:text-default ${defaultClassName}`;
  }
  return `bg-default text-default2 active:text-default hover:text-default ${defaultClassName}`;
}

function isSectionSelected(section, selectedSection) {
  return (
    selectedSection?.id === section?.id ||
    selectedSection?.sectionId === section?.id ||
    (selectedSection?.selectedSubSection &&
      selectedSection?.selectedSubSection.id === section?.id)
  );
}

function SidebarSection({ section, selectedSection, sectionRef, onClick }) {
  const isSelected = isSectionSelected(section, selectedSection);

  const sectionLabel = useTranslatedText({
    resource: section,
    fallbackValue: section?.label,
  });

  if (section.items.length > 0) {
    return (
      <div
        ref={sectionRef}
        className="relative justify-center flex-none p-2 lg:p-0 lg:w-full align-center"
      >
        {/* <!-- Current: "bg-faded text-default", Default: "bg-default text-default2 hover:bg-faded hover:text-default" --> */}
        <a
          className={getSectionClass(isSelected)}
          onClick={() => onClick(section)}
        >
          {sectionLabel}
        </a>
      </div>
    );
  }

  return (
    <div
      ref={sectionRef}
      className="justify-center min-w-24 lg:w-full align-center"
    >
      {/* <!-- Current: "bg-faded text-default", Default: "bg-default text-default2 hover:bg-faded hover:text-default" --> */}
      <button
        type="button"
        className={getSectionClass(isSelected) + " focus:outline-none"}
        onClick={() => onClick(section)}
      >
        {section.label}
        {/* <!-- Expanded: "text-default rotate-90", Collapsed: "text-default2" --> */}
        {section.subSections.length > 0 && (
          <svg
            className={`${
              isSelected ? "text-default rotate-90" : "text-default2"
            } ml-auto h-5 w-5 mr-2 transform group-hover:text-default transition-all ease-in-out duration-150 hidden lg:block`}
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
          </svg>
        )}
      </button>
      {/* <!-- Expandable link section, show/hide based on state. --> */}
      {isSelected && (
        <div className="hidden lg:block" id="sub-menu-1">
          {section.subSections.map((subSection) => (
            <SidebarSubSection
              key={subSection.label}
              section={subSection}
              onClick={onClick}
            />
          ))}
        </div>
      )}
    </div>
  );
}

SidebarSubSection.propTypes = {
  section: PropTypes.object,
  selectedSection: PropTypes.object,
  onClick: PropTypes.func,
};

function SidebarSubSection({ section, selectedSection, onClick }) {
  const { configQuery } = useContext(ConfigContext);
  const { i18n } = useTranslation();

  const subSectionLabel = useMemo(() => {
    if (
      configQuery?.config?.features.includes(
        constants.FEATURES.LANGUAGE_SELECTOR,
      ) &&
      section
    ) {
      return (
        section.translation?.title?.[i18n.resolvedLanguage] ??
        section.translation?.title?.["default"] ??
        section?.label
      );
    } else {
      return section?.label;
    }
  }, [section, i18n.resolvedLanguage, configQuery?.config?.features]);

  return (
    <a
      key={section?.label}
      className={
        getSectionClass(isSectionSelected(section, selectedSection)) +
        " text-sm-body pl-6"
      }
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onClick({
          ...section,
          selectedSubSection: section,
        });
      }}
    >
      {subSectionLabel}
    </a>
  );
}
