import React, { useRef, useContext } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import useOutsideClick from "@/utils/useOutsideClick";

import AccountBadge from "./AccountBadge";
import LayoutContext from "../layoutContext";

AccountDropdown.propTypes = {
  options: PropTypes.array,
};

export default function AccountDropdown({ options }) {
  const { layoutState, setLayoutState } = useContext(LayoutContext);
  const container = useRef(null);

  useOutsideClick(container, () => {
    if (!layoutState.accountDropdownVisible) return;
    setLayoutState((layoutState) => ({
      ...layoutState,
      accountDropdownVisible: false,
    }));
  });

  return (
    <>
      {/* <!-- Account dropdown --> */}
      <div ref={container}>
        <div className="flex items-center">
          <AccountBadge
            onClick={() => {
              setLayoutState((layoutState) => ({
                ...layoutState,
                accountDropdownVisible: !layoutState.accountDropdownVisible,
                languageSelectorOpened: false,
              }));
            }}
            containerStyle={{
              backgroundColor: "var(--color-text-on-primary)",
            }}
          />
        </div>

        {layoutState.accountDropdownVisible && (
          <div className="absolute ml-[-168px] mt-[20px]">
            <div
              className="w-48 text-left shadow-lg bg-header account-dropdown"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              {options.map((option) => {
                const { label, link, ...rest } = option;
                const Component = link ? Link : "a";
                if (link) rest.to = link;

                let klass =
                  "block px-4 py-2 text-on-header hover:text-on-header2 hover:font-bold";
                return (
                  <Component
                    key={`account-dropdown-${label}`}
                    className={klass}
                    role="menuitem"
                    onClick={() => {
                      setLayoutState((layoutState) => ({
                        ...layoutState,
                        accountDropdownVisible: false,
                      }));
                    }}
                    {...rest}
                  >
                    {label}
                  </Component>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
