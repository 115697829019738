import { gql } from "@apollo/client";

export const ORDER_FIELDS = gql`
  fragment OrderFields on OrderHistory {
    id
    identifier
    addressLine1
    addressLine2
    cardBrand
    cardLast4
    gstNo
    invoiceDate
    brands {
      label
    }
    orderItems {
      itemId
      name
      quantity
      price
      notes
      brand {
        label
      }
      translation
      subItems {
        itemId
        name
        quantity
        price
        notes
        translation
        subItems {
          name
          quantity
          price
          notes
          translation
        }
      }
    }
    fulfilmentType
    outlet {
      id
      labelForPickup
      contactNumber
      address
      uen
    }
    paymentBreakdown {
      subtotal
      tax
      totalIncludingTax
      surcharge
      surchargeLabel
      serviceCharge
      donationAmount
      donationLabel
      discount
      isPostTaxDiscount
      deliveryFee
      taxInclusivePrices
    }
    servingDate
    timeslotRangeLabel
    state
    fulfilmentState
    contactName
    contactNumber
    recipientName
    recipientContactNumber
    giftMessage
  }
`;

export const GET_ORDER_HISTORY_QUERY = gql`
  query GetOrderHistory($isPastOrders: Boolean, $page: Int, $perPage: Int) {
    orderHistory(isPastOrders: $isPastOrders, page: $page, perPage: $perPage) {
      pastOrders {
        ...OrderFields
      }
      upcomingOrders {
        ...OrderFields
        courierName
        courierNumber
        trackingUrl
        logisticsType
      }
      page
      totalCount
    }
  }
  ${ORDER_FIELDS}
`;
