export default {
  CONFIG: {
    COUNTRY: "country",
    CURRENCY: "currency",
  },
  FULFILMENT_TYPES: {
    DELIVERY: "delivery",
    PICKUP: "pickup",
    DINE_IN: "dine_in",
  },
  ORDER_STATUSES: {
    CONFIRMED: "confirmed",
    ASSIGNED: "assigned",
    DISPATCHED: "dispatched",
    READY_FOR_PICKUP: "ready_for_pickup",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
  },
  ORDER_STATUS_TIMELINES: {
    PICKUP: ["confirmed", "ready_for_pickup"],
    DELIVERY: ["confirmed", "assigned", "dispatched"],
  },
  USER_TARGET_TYPES: {
    ALL: "all",
    REGISTERED: "registered",
    GUEST: "guest",
  },
  FEATURES: {
    USE_DISH_NOTES_ON_ITEM: "use_dish_notes_on_item",
    ASAP_TIMESLOTS: "asap_timeslot",
    HIDE_CUTLERY_REQUIRED: "hide_cutlery_required",
    ENABLE_REFERRALS: "enable_referrals",
    USE_OPTIMISED_DINER_CART_TYPE: "use_optimised_diner_cart_type",
    PUSH_DISABLED_SECTIONS_TO_BOTTOM: "push_disabled_sections_to_bottom",
    USE_OTP_LOGIN_FOR_ONLINE: "use_otp_login_for_online",
    ALLOW_SOCIAL_LOGIN_FOR_ONLINE: "allow_social_login_for_online",
    LANGUAGE_SELECTOR: "show_language_selector",
    ENABLE_CORS_CREDENTIALS_FOR_OTP_REQUEST:
      "enable_cors_credentials_for_otp_request",
    ANALYTICS_V2: "analytics_v2",
  },
  ASAP_TIMESLOT: "asap",
  FIELD_CONFIGURATION: {
    REQUIRED: "required",
    OPTIONAL: "optional",
    HIDDEN: "hidden",
  },
  OTP_CHANNELS: {
    SMS: "sms",
    EMAIL: "email",
  },
  CURRENT_LANGUAGE: "current_language",
  CORS_CREDENTIALS: {
    INCLUDE: "include",
    OMIT: "omit",
  },
};

export const COOKIES = {
  CSRF_TOKEN: "x_csrf_token",
};

export const STRIPE_PAYMENT_INTENT_SUCCESS = "succeeded";
export const STRIPE_PAYMENT_INTENT_REQUIRES_ACTION = "requires_action";

export const ADYEN_PAYMENT_AUTHORISED = "Authorised";
export const ADYEN_PAYMENT_PENDING = "Pending";

export const REFERRAL_CODE = "referralCode";

export const LG_BREAKPOINT = 1024;

export const PAYMENT_TYPES = {
  PAYNOW: "pay_now",
  PAYMENT_INTENT: "payment_intent",
  OMISE: "omise",
  ADYEN: "adyen",

  pay_now: "PAYNOW",
  payment_intent: "PAYMENT_INTENT",
  omise: "OMISE",
  adyen: "ADYEN",
};

export const INTRO_MODAL_DISMISSED = "introModalDismissed";

export const PRODUCT_CONFIGURATION = "product-configuration";

export const LANGUAGES = {
  en: { nativeName: "English" },
  zh: { nativeName: "中文" },
};
