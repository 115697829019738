import React, { useContext } from "react";

import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ConfigContext from "@/components/Config/configContext";
import constants from "@/utils/constants";

const LoginForm = loadable(() => import("@/components/Login/LoginForm"));
const SessionForm = loadable(() => import("@/components/Session/SessionForm"));

export default function CartLogin() {
  const { t } = useTranslation();
  const { outletQrHash, configQuery } = useContext(ConfigContext);

  const useOtpLoginForOnline = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_OTP_LOGIN_FOR_ONLINE,
  );

  const uiDefinitions = configQuery?.config?.uiDefinitions || [];
  const loginPromptDefinitions = uiDefinitions.filter(
    (definition) => definition.type == "login_prompt",
  );

  const FormComponent = useOtpLoginForOnline ? SessionForm : LoginForm;

  return (
    <div className="p-4 mb-3 cart-login bg-default2">
      <FormComponent
        isCompact
        loginButtonType="primary-outline"
        validateOnBlur={false}
        loginOnly
      >
        <div className="mb-1">
          <strong
            dangerouslySetInnerHTML={{
              __html:
                loginPromptDefinitions.length > 0
                  ? loginPromptDefinitions
                      .map((def) => def.definition.title)
                      .join(", ")
                  : t("login.subheader"),
            }}
          />
        </div>
        <div className="mb-1">
          {loginPromptDefinitions.length > 0
            ? loginPromptDefinitions
                .map((def) => def.definition.body)
                .join(", ")
            : t("login.description")}
        </div>
        {!outletQrHash && !useOtpLoginForOnline && (
          <div>
            {t("login.descriptionBeforeSignupLink")}
            {/* intentional whitespace */}{" "}
            <Link
              className="text-sm font-bold text-link hover:text-link"
              to="/signup"
            >
              {t("login.signupAction")}
            </Link>
          </div>
        )}
      </FormComponent>
    </div>
  );
}
