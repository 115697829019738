import React, { useContext, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Affix from "@/common/Affix";
import ScrollContext from "@/common/Layout/scrollContext";
import PromotedProducts from "@/common/PromotedProducts";
import Spin from "@/common/Spin";
import ConfigContext from "@/components/Config/configContext";
import useTranslatedText from "@/hooks/useTranslationText";
import constants from "@/utils/constants";

import AvailabilityModal from "./AvailabilityModal";
import DeselectedUnavailableModal from "./DeselectedUnavailableModal";
import MenuSection from "./MenuSection";
import MenuSectionDisabledModal from "./MenuSectionDisabledModal";
import PreOrderModal from "./PreOrderModal";
import ProductDetailsModal from "./ProductDetailsModal";
import Sidebar from "./Sidebar";
import MenuContext from "./menuContext";

export function withItemAvailability(menuIsAvailable, sectionItems, t) {
  const additionalData =
    menuIsAvailable === false
      ? {
          isAvailable: false,
          humanizedAvailability: t("menu.menuItem.menuNotAvailable"),
        }
      : {};
  return sectionItems?.map((item) => merge({}, item, additionalData));
}

MenuArea.propTypes = {
  menuData: PropTypes.object,
  selectedItem: PropTypes.object,
  setSelectedItem: PropTypes.func,
  setSelectedCartItem: PropTypes.func,
  setHumanizedLeadTime: PropTypes.func,
  selectedCartItem: PropTypes.object,
  humanizedLeadTime: PropTypes.string,
  humanizedAvailability: PropTypes.string,
  setHumanizedAvailability: PropTypes.func,
  setMenuSectionDisabledReason: PropTypes.func,
  menuSectionDisabledReason: PropTypes.string,
  setSelectedSectionId: PropTypes.func,
  showPreOrderModal: PropTypes.func,
  showAvailabilityModal: PropTypes.func,
  showMenuSectionDisabledModal: PropTypes.func,
  menuError: PropTypes.object,
  loading: PropTypes.bool,
  menuSectionRefs: PropTypes.object,
};

export default function MenuArea({
  menuData,
  selectedItem,
  setSelectedItem,
  selectedCartItem,
  setSelectedCartItem,
  humanizedLeadTime,
  setHumanizedLeadTime,
  humanizedAvailability,
  setHumanizedAvailability,
  setMenuSectionDisabledReason,
  menuSectionDisabledReason,
  setSelectedSectionId,
  showPreOrderModal,
  showAvailabilityModal,
  showMenuSectionDisabledModal,
  menuError,
  loading,
  menuSectionRefs,
}) {
  const { t } = useTranslation();
  const [menuSectionsAffixed, setMenuSectionAffixed] = useState(false);
  const scroller = useContext(ScrollContext);
  const { configQuery } = useContext(ConfigContext);
  const { menu } = useContext(MenuContext);

  const [selectedItemSection, setSelectedItemSection] = useState(null);
  const [showProductDetailsSpinner, setShowProductDetailsSpinner] =
    useState(false);

  const [selectedItemWithAvailability, setSelectedItemWithAvailability] =
    useState(null);
  const [deselectedUnavailable, setDeselectedUnavailable] = useState(null);

  const pushDisabledSectionsToBottom = configQuery?.config?.features?.includes(
    constants.FEATURES.PUSH_DISABLED_SECTIONS_TO_BOTTOM,
  );

  const menuLabel = useTranslatedText({
    resource: menu,
    fallbackValue: t("menu.sidebar.header"),
  });

  const menuSections = useMemo(() => {
    if (pushDisabledSectionsToBottom) {
      return [...(menuData?.sections || [])].sort((a, b) => {
        if (a.disabled && !b.disabled) {
          return 1;
        } else if (b.disabled && !a.disabled) {
          return -1;
        }
        return 0;
      });
    }
    return menuData?.sections;
  }, [menuData?.sections, pushDisabledSectionsToBottom]);

  // HACK: setTimeout to change value somehow allows Affix to trigger onChange as desired,
  // even though this value isn't used anywhere
  const [affixKey, setAffixKey] = useState(0);
  useEffect(() => {
    if (!menuSectionsAffixed) {
      setTimeout(() => setAffixKey(affixKey + 1), 1);
    }
  }, [menuSectionsAffixed]);
  // end of HACK

  useEffect(() => {
    setSelectedItemWithAvailability(
      selectedItem
        ? withItemAvailability(menuData?.isAvailable, [selectedItem], t)[0]
        : null,
    );
    if (!selectedItem) {
      setSelectedItemSection(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem && menuData?.id) {
      setShowProductDetailsSpinner(true);
    } else {
      setShowProductDetailsSpinner(false);
    }
  }, [menuData?.id]);

  useEffect(() => {
    if (showProductDetailsSpinner) {
      // we assume menuData is present when showProductDetailsSpinner is true
      // find the item on the menu with the same label as the currently selected item
      let itemOnMenu = menuSections
        .flatMap((section) => section.items)
        .find((el) => {
          return el.label === selectedItem.label;
        });
      if (!itemOnMenu) {
        setDeselectedUnavailable(selectedItem.label);
        setSelectedItem(null);
      } else if (itemOnMenu.id !== selectedItem.id) {
        setSelectedItem(itemOnMenu);
      }
      setShowProductDetailsSpinner(false);
    }
  }, [menuData?.id, selectedItem, showProductDetailsSpinner]);

  if (!menuData) {
    return (
      <div className="py-18" data-testid="spinner">
        <div className="mt-6 text-sm font-bold text-center">
          {loading ? (
            <>
              <Spin logoClassName="w-10 h-10" />
              <div className="mt-4">{t("menu.loading")}</div>
            </>
          ) : (
            t("menu.noMenu")
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames([
        "grid max-w-screen-xl grid-cols-1 gap-3 px-3 mx-auto mb-12 sm:gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-4",
        loading && "opacity-90",
      ])}
    >
      <section
        aria-labelledby="timeline-title"
        className="relative lg:col-start-1 lg:col-span-1 lg:pt-0"
      >
        {/* opacity-0 div to mimic space needed for menu labels */}
        <div
          className={classNames(
            "text-2xl md:text-3xl font-display text-center pointer-events-none opacity-0 pt-6 pb-6 lg:hidden",
          )}
        >
          {menuLabel}
        </div>
        <Affix
          offsetTop={64}
          className={classNames([menuSectionsAffixed && "relative z-20"])}
          target={() => scroller.current}
          onChange={setMenuSectionAffixed}
        >
          <div className="hidden lg:block">
            <Sidebar
              menuSectionRefs={menuSectionRefs}
              affixed={menuSectionsAffixed}
              sections={menuSections || []}
              onSectionSelect={setSelectedSectionId}
            />
          </div>
        </Affix>
        <div
          className={classNames([
            "lg:hidden left-0 z-10 w-full",
            menuSectionsAffixed ? "fixed bottom-0" : "absolute top-0",
          ])}
        >
          <Sidebar
            menuSectionRefs={menuSectionRefs}
            affixed={menuSectionsAffixed}
            sections={menuSections || []}
            onSectionSelect={setSelectedSectionId}
          />
        </div>
      </section>
      <div className="pt-6 space-y-6 sm:pt-12 lg:col-start-2 lg:col-span-3">
        <section aria-labelledby="menu-items">
          <div data-testid="menu">
            {menuError?.message && (
              <div className="text-danger">{menuError?.message}</div>
            )}
            {menuData && (
              <div className="space-y-12">
                {menuSections?.map((section, index) => (
                  <MenuSection
                    key={index}
                    sectionRef={(el) =>
                      (menuSectionRefs.current[section?.id] = el)
                    }
                    menuSectionRefs={menuSectionRefs}
                    sidebarRefIndex={index}
                    section={section}
                    setSelectedItem={setSelectedItem}
                    setSelectedItemSection={(value) =>
                      setSelectedItemSection(value ?? section)
                    }
                    showPreOrderModal={showPreOrderModal}
                    showAvailabilityModal={showAvailabilityModal}
                    showMenuSectionDisabledModal={showMenuSectionDisabledModal}
                  />
                ))}

                {/* Handle promoted (cross-sell) products */}
                <PromotedProducts setSelectedItem={setSelectedItem} />

                <ProductDetailsModal
                  selectedCartItem={selectedCartItem}
                  setSelectedCartItem={setSelectedCartItem}
                  selectedItem={selectedItemWithAvailability}
                  setSelectedItem={setSelectedItem}
                  selectedItemSection={selectedItemSection}
                  showPreOrderModal={showPreOrderModal}
                  showAvailabilityModal={showAvailabilityModal}
                  showMenuSectionDisabledModal={showMenuSectionDisabledModal}
                  showLoadingSpinner={showProductDetailsSpinner || loading}
                />
                <PreOrderModal
                  humanizedLeadTime={humanizedLeadTime}
                  closeModal={() => setHumanizedLeadTime("")}
                />
                <AvailabilityModal
                  humanizedAvailability={humanizedAvailability}
                  closeModal={() => setHumanizedAvailability("")}
                />
                <MenuSectionDisabledModal
                  menuSectionDisabledReason={menuSectionDisabledReason}
                  closeModal={() => setMenuSectionDisabledReason("")}
                />
                <DeselectedUnavailableModal
                  label={deselectedUnavailable}
                  visible={!!deselectedUnavailable}
                  closeModal={() => setDeselectedUnavailable(null)}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
