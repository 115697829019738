/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
} from "react";

import { useLazyQuery } from "@apollo/client";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import MenuIcon from "@heroicons/react/outline/MenuIcon";
import ShoppingCartIcon from "@heroicons/react/outline/ShoppingCartIcon";
import classNames from "classnames";
import dayjs from "dayjs";
import sumBy from "lodash/sumBy";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Affix from "@/common/Affix";
import Badge from "@/common/Badge";
import Button from "@/common/Button";
import HasErrorTooltip from "@/common/HasErrorTooltip";
import Image from "@/common/Image";
import LanguageSelector from "@/common/LanguageSelector";
import ScrollContext from "@/common/Layout/scrollContext";
import Spin from "@/common/Spin";
import { useAccountNavigations } from "@/components/Account/AccountNavigation";
import CartContext from "@/components/Cart/cartContext";
import ConfigContext from "@/components/Config/configContext";
import { TIMESLOTS_QUERY } from "@/graphql/queries/getTimeslots";
import useAnalytics from "@/hooks/useAnalytics";
import useAnalyticsV2 from "@/hooks/useAnalyticsV2";
import constants, { LG_BREAKPOINT } from "@/utils/constants";
import { useListenEvent } from "@/utils/eventBus";
import formatPrice from "@/utils/formatPrice";
import getUrlParams from "@/utils/getUrlParams";

import AccountDropdown from "./AccountDropdown";
import AddressTimeslotHeader from "./AddressTimeslotHeader";
import Announcement from "./Announcement";
import AnnouncementBanner from "./AnnouncementBanner";
import BlockModal from "./BlockModal";
import MobileFulfilmentPanel from "./MobileFulfilmentPanel";
import Navigation from "./Navigation";
import SelectedAddressLabel from "./SelectedAddressLabel";
import SelectedTimeslotLabel from "./SelectedTimeslotLabel";
import TableIcon from "./TableIcon";
import LayoutContext from "../layoutContext";

Header.propTypes = {
  isTransparentHeader: PropTypes.bool,
  headerCart: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  addressModal: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  timeslotModal: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

Header.defaultProps = {
  isTransparentHeader: false,
};

export default function Header({
  isTransparentHeader,
  headerCart,
  addressModal,
  timeslotModal,
}) {
  const dinerManifest = window.dinerManifest;
  const hasMobileLogo = !!dinerManifest?.header?.logo?.mobile?.src;

  const scroller = useContext(ScrollContext);
  const { layoutState, setLayoutState } = useContext(LayoutContext);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { analytics, events } = useAnalytics();
  const { trackEvent, eventsV2 } = useAnalyticsV2();
  const urlParams = getUrlParams(useLocation());
  const [isCartOpen, setCartOpen] = useState(false);
  const [isAddressModalVisible, setCreateAddressOpen] = useState(false);
  const [isTimeslotModalVisible, setTimeslotModalVisible] = useState(false);
  const [isMobileFulfilmentPanelVisible, setMobileFulfilmentPanelVisible] =
    useState(false);
  const [selectedTimeslotError, setSelectedTimeslotError] = useState(null);
  const [allOutletsClosedError, setAllOutletsClosedError] = useState(null);
  const [currentOutletClosedError, setCurrentOutletClosedError] =
    useState(null);
  const [headerAffixed, setHeaderAffixed] = useState(false);
  const { outletQrHash, tableQrHash, pathConfig, configQuery } =
    useContext(ConfigContext);
  const hideQrTimeslots =
    configQuery?.currentStorefront?.config?.hide_qr_timeslots &&
    window.location.pathname.startsWith("/qr/");
  const onlyEnableOrderWithinOperatingHours =
    configQuery?.currentStorefront?.config
      ?.only_enable_ordering_within_operating_hours;

  const mobileHeader = useRef(null);
  const headerRef = useRef(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [withinOperatingHours, setWithinOperatingHours] = useState(false);
  const [beforeOpeningHours, setBeforeOpeningHours] = useState(false);
  const [nextOpeningTime, setNextOpeningTime] = useState(null);
  const [disableBlock, setDisableBlock] = useState(true);
  const [disableCountDown, setDisableCountDown] = useState(true);
  const [countDownTime, setCountDownTime] = useState(null);
  const [closingTime, setClosingTime] = useState(null);

  const [getTimeslots, { data: timeslotsData }] = useLazyQuery(
    TIMESLOTS_QUERY,
    {
      context: { graph: "diners" },
      fetchPolicy: "cache-and-network",
    },
  );
  const {
    cartQuery: data,
    cartLoading,
    brandIsOnlyAvailableForPickup,
    hasOnlyOneOutletToPickupFrom,
  } = useContext(CartContext);

  const hasLanguageSelector = configQuery?.config?.features.includes(
    constants.FEATURES.LANGUAGE_SELECTOR,
  );

  const [isMobileVer, setIsMobileVer] = useState(
    window.innerWidth < LG_BREAKPOINT,
  );

  const updateWindowDimensions = () => {
    setIsMobileVer(window.innerWidth < LG_BREAKPOINT);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    function onScrollOrResize() {
      const transparent =
        isTransparentHeader &&
        window.innerWidth >= 1024 &&
        scroller.current.scrollTop === 0;
      setLayoutState((state) => ({
        ...state,
        transparent,
      }));
    }

    if (scroller?.current) {
      const scrollerRef = scroller?.current;
      scrollerRef.addEventListener("scroll", onScrollOrResize);
      window.addEventListener("resize", onScrollOrResize);
      onScrollOrResize();
      return () => {
        scrollerRef.removeEventListener("scroll", onScrollOrResize);
        window.removeEventListener("resize", onScrollOrResize);
      };
    }
  }, [scroller, isTransparentHeader]);

  useListenEvent("selectAddress", () => setCreateAddressOpen(true));
  useListenEvent("selectTimeslot", () => setTimeslotModalVisible(true));
  useListenEvent("openCart", () => setCartOpen(true));

  const [selectedTimeslotLabel, setSelectedTimeslotLabel] = useState(
    t("navbar.timeslot"),
  );

  const [visible, setVisibility] = useState(false);

  const changeVisibilityOnClick = (visibility) => {
    setVisibility(visibility);
  };

  // only use countdown to trigger checking store status when it hits zero, but it's not displayed
  const countdownRenderer = ({ completed }) => {
    if (completed) {
      checkStoreStatus();
    }
    return <></>;
  };

  useEffect(() => {
    // set interval to check the operating hours if enable ordering only within operating hours is turned on
    if (onlyEnableOrderWithinOperatingHours) {
      //first call
      checkStoreStatus();
      let timer;

      //will check the store status whether it's open or closed every 1 minute
      timer = setInterval(() => {
        checkStoreStatus();
      }, 60000);

      return () => clearInterval(timer);
    }
  }, [timeslotsData?.getTimeslots]);

  function checkStoreStatus() {
    if (timeslotsData?.getTimeslots?.outletOperatingHours) {
      setWithinOperatingHours(false);
      const currentTime = new Date();
      const midnight = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        0,
        0,
        0, // at 00:00:00 hours
      );
      const currentTimeInSeconds =
        (currentTime.getTime() - midnight.getTime()) / 1000;
      let startOpeningHours = null;
      let isOpen = false;
      timeslotsData?.getTimeslots?.outletOperatingHours?.forEach((hours) => {
        if (
          currentTimeInSeconds > hours.start &&
          currentTimeInSeconds < hours.end
        ) {
          isOpen = true;
          setDisableBlock(true);
          setWithinOperatingHours(true);
          if ((hours.end - currentTimeInSeconds) / 60 < 15) {
            setDisableCountDown(false);
            // set time in milliseconds
            setCountDownTime((hours.end - currentTimeInSeconds) * 1000);
            setClosingTime(convertToAMPM(hours.end));
          } else {
            setDisableCountDown(true);
          }
        } else if (!isOpen) {
          setDisableBlock(false);
        }
        if (!isOpen && currentTimeInSeconds < hours.start) {
          setBeforeOpeningHours(true);
          setDisableCountDown(true);
          // only set next opening time if it has not been set before, or it is past the current opening time
          if (!startOpeningHours || startOpeningHours > hours.start) {
            startOpeningHours = hours.start;
          }
        }
      });
      if (startOpeningHours) {
        setNextOpeningTime(convertToAMPM(startOpeningHours));
      }
    }
  }
  useEffect(() => {
    if (
      data?.cart?.servingDate &&
      data?.cart?.fulfilmentType &&
      data?.cart?.outletId
    ) {
      getTimeslots({
        variables: {
          servingDate: selectedDate || data.cart.servingDate,
        },
      });
    }
  }, [
    data?.cart?.servingDate,
    data?.cart?.fulfilmentType,
    data?.cart?.outletId,
  ]);

  useEffect(() => {
    if (data?.cart) {
      getTimeslots({
        variables: {
          servingDate: selectedDate || data.cart.servingDate,
        },
      });
    }
  }, [data?.cart?.additionalLeadTime]);

  useEffect(() => {
    if (data?.cart?.servingDate && data?.cart?.timeslot) {
      const cartDate = dayjs(data?.cart?.servingDate ?? null).format("MMM D");
      setSelectedTimeslotLabel(
        t("timeslotPicker.selectedTimeslotLabel", {
          cartDate,
          rangeLabel: data.cart.timeslot.rangeLabel,
        }),
      );
    } else {
      setSelectedTimeslotLabel(t("navbar.timeslot"));
    }
  }, [data?.cart?.servingDate, data?.cart?.timeslot, t, i18n.resolvedLanguage]);

  // on change fulfilment type, we will retrieve the new list of timeslots,
  // so we will wait for the timeslots to load before we check for errors
  useEffect(() => {
    if (timeslotsData?.getTimeslots?.timeslots?.length) {
      // Do not show validation if timeslot is ASAP
      const isAsapTimeslotSelected =
        data?.cart?.timeslotType === constants.ASAP_TIMESLOT;
      const timeslotsForFulfilmentType = isAsapTimeslotSelected
        ? []
        : timeslotsData.getTimeslots.timeslots.filter(
            (timeslot) =>
              !data?.cart?.fulfilmentType ||
              timeslot.fulfilmentTypes.includes(data?.cart?.fulfilmentType),
          );
      setSelectedTimeslotError(
        !isAsapTimeslotSelected &&
          !timeslotsForFulfilmentType.some(
            (timeslot) => timeslot.id === data?.cart?.timeslot?.id,
          )
          ? t("timeslotPicker.fulfilmentTypeUnavailable", {
              fulfilmentType: data?.cart?.fulfilmentType,
            })
          : null,
      );
    }
  }, [timeslotsData?.getTimeslots?.timeslots, data?.cart?.timeslot?.id]);

  useEffect(() => {
    if (data?.cart?.outletClosed) {
      const today = new Date();
      const suffix =
        `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` ===
        data?.cart?.servingDate
          ? "Today"
          : "";

      setAllOutletsClosedError(t(`addressPicker.outletIsClosed${suffix}`));
      setCurrentOutletClosedError(null);
    } else {
      setCurrentOutletClosedError(null);
      setAllOutletsClosedError(null);
    }
  }, [data?.cart?.outletClosed, data?.cart?.servingDate, t]);

  const [selectedAddressOrPickupLabel, setSelectedAddressOrPickupLabel] =
    useState(t("navbar.address"));

  useEffect(() => {
    if (data?.cart?.fulfilmentType === constants.FULFILMENT_TYPES.PICKUP) {
      setSelectedAddressOrPickupLabel(
        data?.cart?.outlet?.labelForPickup
          ? outletQrHash
            ? data?.cart?.outlet?.labelForPickup
            : t("navbar.pickupFrom", {
                labelForPickup: data?.cart?.outlet?.labelForPickup,
              })
          : t("navbar.selectAPickupLocation"),
      );
    } else if (
      data?.cart?.fulfilmentType === constants.FULFILMENT_TYPES.DINE_IN
    ) {
      setSelectedAddressOrPickupLabel(data?.cart?.outlet?.labelForPickup);
    } else {
      setSelectedAddressOrPickupLabel(
        data?.cart?.address?.line1 || t("navbar.address"),
      );
    }
  }, [data?.cart, outletQrHash, layoutState.language]);

  const cartTotalLabel = useMemo(
    () =>
      // price should not show negative
      formatPrice(
        data?.cart?.topLevelItems?.length > 0
          ? Math.max(0, data?.cart?.paymentBreakdown?.total)
          : 0,
      ),
    [data?.cart?.topLevelItems?.length, data?.cart?.paymentBreakdown?.total],
  );

  useEffect(() => {
    if (isCartOpen) {
      analytics.trackEventWithProperties(
        events.cart_viewed,
        {},
        { configQuery },
      );
      trackEvent(eventsV2.viewCart, data?.cartOptimised?.topLevelItems);
    }
  }, [isCartOpen]);

  useEffect(() => {
    if (urlParams.cartOpen === "true") {
      analytics.track(events.cart_viewed, {}, { configQuery });
      setCartOpen(true);
      // remove url params
      navigate(window.location.pathname, { replace: true });
    }
  }, [urlParams.cartOpen]);

  const isAddressOrOutletSelected = !!(
    data?.cart?.address?.id || data?.cart?.outletId
  );

  let LogoComponent, logoComponentProps;
  if (outletQrHash) {
    LogoComponent = "div";
    logoComponentProps = { className: "py-2" };
  } else {
    if (dinerManifest?.menuOnly && dinerManifest?.homeUrl) {
      LogoComponent = "a";
      logoComponentProps = {
        href: dinerManifest?.homeUrl,
        target: "_blank",
        rel: "noreferrer",
        name: "Home",
        className: "py-2",
      };
    } else {
      LogoComponent = Link;
      logoComponentProps = { to: "/", name: "Home", className: "py-2" };
    }
  }

  const cartItemCount =
    sumBy(data?.cart?.topLevelItems, (item) => item.quantity) ?? 0;

  const accountNavigations = useAccountNavigations();

  function renderOrderNowButton() {
    return (
      !headerCart && (
        <Link to={pathConfig?.menuPath}>
          <Button type="primary" className="w-21">
            {t("navbar.orderNow")}
          </Button>
        </Link>
      )
    );
  }

  function convertToAMPM(seconds) {
    let meridiem = "AM";
    let [hour, min] = String(seconds / 3600).split(".");
    if (hour > 12) {
      hour = hour - 12;
      meridiem = "PM";
    }
    if (!min) {
      return hour + ":00 " + meridiem;
    } else {
      min = String(min * 60).substring(0, 2);
      return hour + ":" + min + " " + meridiem;
    }
  }

  return (
    <div
      className={classNames("w-full lg:fixed", {
        "z-40": !(isCartOpen || isMobileFulfilmentPanelVisible),
        "z-50": isCartOpen || isMobileFulfilmentPanelVisible,
      })}
    >
      {!withinOperatingHours &&
        !disableBlock &&
        onlyEnableOrderWithinOperatingHours && (
          <>
            <BlockModal
              isBeforeOpeningHours={beforeOpeningHours}
              nextOpeningTime={nextOpeningTime}
            />
          </>
        )}
      {!disableCountDown && onlyEnableOrderWithinOperatingHours && (
        <>
          <Announcement>
            {t("block.closingCountdown", { closingTime })}
            <Countdown
              date={Date.now() + countDownTime}
              renderer={countdownRenderer}
            />
          </Announcement>
        </>
      )}
      <AnnouncementBanner />
      <nav
        className={classNames([
          "transition-all",
          layoutState.transparent ? "" : "shadow-md bg-header text-on-header",
        ])}
      >
        <div className="px-3 mx-auto max-w-screen-xl sm:px-6">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:h-16">
            <div className="flex">
              <div className="flex flex-shrink-0 justify-between items-center w-full lg:w-auto">
                <LogoComponent {...logoComponentProps}>
                  {layoutState.transparent &&
                  dinerManifest?.header?.logo?.onTransparentHeader ? (
                    <Image
                      className="hidden w-auto h-8 lg:block"
                      src={
                        dinerManifest?.header?.logo?.onTransparentHeader?.src
                      }
                      alt={
                        dinerManifest?.header?.logo?.onTransparentHeader?.alt
                      }
                      style={
                        dinerManifest?.header?.logo?.onTransparentHeader?.style
                      }
                    />
                  ) : (
                    <Image
                      className="hidden w-auto h-8 lg:block"
                      src={dinerManifest?.header?.logo?.src}
                      alt={dinerManifest?.header?.logo?.alt}
                      style={dinerManifest?.header?.logo?.style}
                    />
                  )}
                  {hasMobileLogo ? (
                    <Image
                      className="w-auto h-8 lg:hidden"
                      src={dinerManifest?.header?.logo?.mobile?.src}
                      alt={dinerManifest?.header?.logo?.mobile?.alt}
                      style={dinerManifest?.header?.logo?.mobile?.style}
                    />
                  ) : (
                    <Image
                      className="w-auto h-8 lg:hidden"
                      src={dinerManifest?.header?.logo?.src}
                      alt={dinerManifest?.header?.logo?.alt}
                      style={dinerManifest?.header?.logo?.style}
                    />
                  )}
                </LogoComponent>
                {!outletQrHash && (
                  <div className="flex items-center space-x-4 lg:hidden">
                    {renderOrderNowButton()}
                    {hasLanguageSelector && (
                      <LanguageSelector mobile headerRef={headerRef} />
                    )}
                    <button
                      type="button"
                      className="py-4 focus:outline-none"
                      aria-controls="mobile-menu"
                      onClick={() =>
                        setLayoutState((state) => ({
                          ...state,
                          languageSelectorOpened: false,
                          isMobileMenuOpen: !state.isMobileMenuOpen,
                        }))
                      }
                    >
                      <span className="sr-only">Open mobile menu</span>
                      <MenuIcon className="w-6 h-6 text-on-header" />
                    </button>
                  </div>
                )}
                {tableQrHash && (
                  <div className="flex lg:hidden">
                    <div
                      className={classNames([
                        "mx-2",
                        layoutState.transparent
                          ? "text-on-transparent-header"
                          : "text-primary",
                      ])}
                    >
                      <TableIcon />
                    </div>
                    {t("navbar.tableName", {
                      tableName: data?.cart?.tableName,
                    })}
                  </div>
                )}
              </div>
              {!outletQrHash && (
                <div className="hidden lg:block">
                  <Navigation isTransparent={layoutState.transparent} />
                </div>
              )}
            </div>

            {headerCart &&
              React.cloneElement(headerCart, {
                isCartOpen,
                closeCart: () => setCartOpen(!isCartOpen),
              })}

            {addressModal &&
              React.cloneElement(addressModal, {
                isAddressModalVisible,
                closeAddressModal: () => setCreateAddressOpen(false),
                openTimeslotModal: () => setTimeslotModalVisible(true),
              })}

            {timeslotModal &&
              React.cloneElement(timeslotModal, {
                isTimeslotModalVisible,
                closeTimeslotModal: () => setTimeslotModalVisible(false),
                selectedDate,
                setSelectedDate,
                cartQuery: data,
              })}

            <div className="hidden ml-6 space-x-3 truncate lg:flex lg:items-center">
              {headerCart && (
                <>
                  <AddressTimeslotHeader
                    cartLoading={cartLoading}
                    currentOutletClosedError={currentOutletClosedError}
                    allOutletsClosedError={allOutletsClosedError}
                    selectedTimeslotError={selectedTimeslotError}
                    selectedAddressOrPickupLabel={selectedAddressOrPickupLabel}
                    selectedTimeslotLabel={selectedTimeslotLabel}
                    isAddressOrOutletSelected={isAddressOrOutletSelected}
                    setCreateAddressOpen={setCreateAddressOpen}
                    setTimeslotModalVisible={setTimeslotModalVisible}
                    isTransparent={layoutState.transparent}
                    isAddressModalVisible={isAddressModalVisible}
                    isTimeslotModalVisible={isTimeslotModalVisible}
                    brandIsOnlyAvailableForPickup={
                      brandIsOnlyAvailableForPickup ||
                      !data?.cart?.canChangeOutlet
                    }
                    hasOnlyOneOutletToPickupFrom={
                      hasOnlyOneOutletToPickupFrom ||
                      !data?.cart?.canChangeOutlet
                    }
                    tableName={data?.cart?.tableName}
                    tableQrHash={tableQrHash}
                    hideQrTimeslots={hideQrTimeslots}
                  />
                  <Badge
                    size="small"
                    count={cartItemCount}
                    style={{
                      backgroundColor: "var(--color-primary)",
                      color: "var(--color-text-on-primary)",
                      right: "100%",
                      top: 4,
                      height: 20,
                      minWidth: 20,
                      lineHeight: "20px",
                      borderRadius: 20,
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <Button
                      type="primary"
                      leftIcon={
                        <ShoppingCartIcon className="transform -scale-x-1" />
                      }
                      onClick={() => {
                        if (headerCart) {
                          setCartOpen(!isCartOpen);
                        } else {
                          navigate(`${pathConfig?.menuPath}?cartOpen=true`);
                        }
                      }}
                    >
                      {data?.cart?.paymentBreakdown?.total != null ? (
                        cartTotalLabel
                      ) : (
                        <Spin logoClassName="w-5 h-5 text-on-primary" />
                      )}
                    </Button>
                  </Badge>
                </>
              )}

              {renderOrderNowButton()}
              {!outletQrHash && (
                <AccountDropdown options={accountNavigations} />
              )}
              {hasLanguageSelector && <LanguageSelector />}
            </div>
          </div>
        </div>
        {headerCart && (
          <Affix
            className={classNames([headerAffixed && "relative z-30"])}
            target={() => scroller.current}
            onChange={setHeaderAffixed}
          >
            <div
              className={classNames([
                "max-w-screen-xl px-3 sm:px-6 mx-auto bg-header text-on-header",
                headerAffixed && "shadow lg:shadow-none",
              ])}
              ref={mobileHeader}
            >
              <div className="flex justify-between items-center h-16 lg:hidden">
                <HasErrorTooltip
                  color="var(--color-danger)"
                  className="block lg:hidden"
                  getPopupContainer={() =>
                    mobileHeader?.current ?? document.body
                  }
                  visible={
                    !headerAffixed &&
                    !isCartOpen &&
                    !isMobileFulfilmentPanelVisible &&
                    !!currentOutletClosedError
                  }
                  error={currentOutletClosedError}
                >
                  <HasErrorTooltip
                    color="var(--color-danger)"
                    className="block lg:hidden"
                    getPopupContainer={() =>
                      mobileHeader?.current ?? document.body
                    }
                    visible={
                      !headerAffixed &&
                      !isCartOpen &&
                      !isMobileFulfilmentPanelVisible &&
                      !!selectedTimeslotError
                    }
                    error={selectedTimeslotError}
                  >
                    <HasErrorTooltip
                      color="var(--color-danger)"
                      className="block lg:hidden"
                      getPopupContainer={() =>
                        mobileHeader?.current ?? document.body
                      }
                      visible={
                        !headerAffixed &&
                        !isCartOpen &&
                        !isMobileFulfilmentPanelVisible &&
                        !!allOutletsClosedError
                      }
                      error={allOutletsClosedError}
                    >
                      <div
                        className="flex flex-col justify-center text-sm space-y-0.5 overflow-x-hidden w-full"
                        onClick={() => {
                          if (!hideQrTimeslots || !outletQrHash) {
                            setMobileFulfilmentPanelVisible(true);
                          }
                        }}
                      >
                        <div className="truncate">
                          {selectedAddressOrPickupLabel}
                        </div>
                        {!hideQrTimeslots && (
                          <div className="flex items-center w-full font-bold">
                            <>
                              {selectedTimeslotLabel}
                              <ChevronRightIcon className="inline-block ml-1 w-3 h-3" />
                            </>
                          </div>
                        )}
                      </div>
                    </HasErrorTooltip>
                  </HasErrorTooltip>
                </HasErrorTooltip>

                <div className="flex items-center">
                  <button
                    type="button"
                    className="py-4 pl-4 focus:outline-none"
                    onClick={() => setCartOpen(!isCartOpen)}
                  >
                    <Badge
                      size="small"
                      count={cartItemCount}
                      offset={[-24, 0]}
                      style={{
                        backgroundColor: "var(--color-primary)",
                        color: "var(--color-text-on-primary)",
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      <ShoppingCartIcon className="w-6 h-6 transform -scale-x-1 text-on-header" />
                    </Badge>
                  </button>
                </div>
              </div>
            </div>
          </Affix>
        )}
        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        {layoutState.isMobileMenuOpen && (
          <>
            <div
              className="absolute z-10 w-screen h-screen mobile-menu-overlay"
              onClick={() =>
                setLayoutState((state) => ({
                  ...state,
                  languageSelectorOpened: false,
                  isMobileMenuOpen: false,
                }))
              }
            />
            <div
              className="absolute z-10 w-full bg-header text-on-header lg:hidden"
              id="mobile-menu"
            >
              <Navigation
                isMobile
                isTransparent={layoutState.transparent}
                additionalLinks={accountNavigations}
              />
            </div>
          </>
        )}
        <div
          className={classNames([
            "lg:hidden",
            isAddressModalVisible || isTimeslotModalVisible ? "hidden" : "",
          ])}
        >
          <MobileFulfilmentPanel
            visible={isMobileFulfilmentPanelVisible}
            closePanel={() => setMobileFulfilmentPanelVisible(false)}
          >
            {!(brandIsOnlyAvailableForPickup && hasOnlyOneOutletToPickupFrom) &&
            data?.cart?.canChangeOutlet ? (
              <HasErrorTooltip
                color="var(--color-danger)"
                className="block md:hidden"
                visible={
                  isMobileFulfilmentPanelVisible &&
                  !isAddressModalVisible &&
                  !isTimeslotModalVisible
                }
                error={currentOutletClosedError}
                isMobileVer={isMobileVer}
              >
                <SelectedAddressLabel
                  className={
                    currentOutletClosedError && "text-danger border-danger"
                  }
                  onClick={() => setCreateAddressOpen(true)}
                  label={selectedAddressOrPickupLabel}
                />
              </HasErrorTooltip>
            ) : (
              <SelectedAddressLabel
                className={
                  currentOutletClosedError && "text-danger border-danger"
                }
                readOnly
                label={selectedAddressOrPickupLabel}
              />
            )}
            {tableQrHash && (
              <div className="flex items-center px-1 truncate cursor-pointer">
                <div
                  className={classNames([
                    "mr-2",
                    layoutState.transparent
                      ? "text-on-transparent-header"
                      : "text-primary",
                  ])}
                >
                  <TableIcon />
                </div>
                {t("navbar.tableName", {
                  tableName: data?.cart?.tableName,
                })}
              </div>
            )}

            {isAddressOrOutletSelected ? (
              <HasErrorTooltip
                color="var(--color-danger)"
                className="block md:hidden"
                visible={
                  isMobileFulfilmentPanelVisible &&
                  !isAddressModalVisible &&
                  !isTimeslotModalVisible &&
                  !!selectedTimeslotError
                }
                isMobileVer={isMobileVer}
                error={selectedTimeslotError}
              >
                <HasErrorTooltip
                  color="var(--color-danger)"
                  className="block md:hidden"
                  visible={
                    isMobileFulfilmentPanelVisible &&
                    !isAddressModalVisible &&
                    !isTimeslotModalVisible &&
                    !!allOutletsClosedError
                  }
                  isMobileVer={isMobileVer}
                  error={allOutletsClosedError}
                >
                  <SelectedTimeslotLabel
                    className={
                      (selectedTimeslotError || allOutletsClosedError) &&
                      "text-danger border-danger"
                    }
                    onClick={() => setTimeslotModalVisible(true)}
                    label={selectedTimeslotLabel}
                  />
                </HasErrorTooltip>
              </HasErrorTooltip>
            ) : (
              <HasErrorTooltip
                color="var(--color-danger)"
                className="block md:hidden"
                visible={visible}
                isMobileVer={isMobileVer}
                error={t("navbar.addressToolTip")}
              >
                <SelectedTimeslotLabel
                  className="opacity-50"
                  label={selectedTimeslotLabel}
                  onClick={() =>
                    changeVisibilityOnClick(
                      isMobileFulfilmentPanelVisible &&
                        !isAddressModalVisible &&
                        !isTimeslotModalVisible,
                    )
                  }
                />
              </HasErrorTooltip>
            )}
          </MobileFulfilmentPanel>
        </div>
      </nav>
    </div>
  );
}
